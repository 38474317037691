<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="SEND MESSAGE"
        :filter="filter"
        placeholder="Search Message"
        :hasDropdown="false"
        :hasFilter="false"
        @search="handleSearch"
        routerPath="/sendmessage/detail/0"
      />
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <TableListSegmentPanel
          :fields="fields"
          :items="items"
          :isBusy="isBusy"
          :filter="filter"
          :rows="rows"
          :pageOptions="pageOptions"
          @page="pagination"
          :showingTo="showingTo"
          @perpage="hanndleChangePerpage"
          @edit="editMessage"
          @resend="resendMessage"
          @cancel="cancelMessage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuPanel from "@/components/automation/header/HeaderMenuPanel";
import TableListSegmentPanel from "@/components/automation/segmentation/TableListSegmentPanel";
export default {
  components: {
    OtherLoading,
    HeaderMenuPanel,
    TableListSegmentPanel,
  },
  data() {
    return {
      isLoading: true,
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-200",
        },
        {
          key: "send_time",
          label: "Send Time",
        },
        {
          key: "send_user",
          label: "Send User",
          thClass: "w-100px",
        },
        {
          key: "send_success",
          label: "Send Success",
          thClass: "w-100px",
        },
        {
          key: "send_success_percen",
          label: "Send Success Percent",
          thClass: "w-200",
        },
        {
          key: "status_id",
          label: "Status",
          class: "w-200",
        },
        {
          key: "action",
          label: "Action",
        },
        {
          key: "list",
          label: "",
        },
      ],
      items: [],
      isBusy: false,
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 0,
      idMsg: 0,
    };
  },
  async created() {
    await this.getListSegmentation();
  },
  methods: {
    handleSearch() {
      this.getListSegmentation();
    },
    hanndleChangePerpage(value) {
      this.filter = value;
      this.getListSegmentation();
    },
    pagination(value) {
      this.filter = value;
      this.getListSegmentation();
    },
    async getListSegmentation() {
      this.isBusy = true;
      await this.$store.dispatch("getSegmentationList", this.filter);
      const data = this.$store.state.automation.segmentationList;
      if (data.result === 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.count;

        this.showingTo = this.items?.length >= 10 ? 10 : this.items.length;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
      this.isBusy = false;
    },
    editMessage(id) {
      //   window.location.href = `/sendmessage/detail/${id}`;
      this.$router.push(`/sendmessage/detail/${id}`);
    },
    resendMessage(id) {
      this.idMsg = id;
      this.confirmAlert({ message: "Confirm Send Message Again?" }).then(
        (val) => {
          if (val.isConfirmed) {
            this.resendMsg(id);
          }
        }
      );
    },
    async resendMsg(show) {
      if (show) {
        this.isLoading = true;
        await this.$store.dispatch("resendSendMessage", this.idMsg);
        const resp = this.$store.state.automation.resendMessage;
        if (resp.result === 1) {
          this.successAlert().then(() => {
            this.getListSegmentation();
          });
        } else {
          this.isLoading = false;
          this.errorAlert(resp.message);
        }
      }
    },
    cancelMessage(id) {
      this.idMsg = id;
      this.confirmAlert({ message: "Confirm Cancellation Message?" }).then(
        (val) => {
          if (val.isConfirmed) {
            this.cancelMsg(id);
          }
        }
      );
    },
    async cancelMsg(show) {
      if (show) {
        this.isLoading = true;
        await this.$store.dispatch("cancelMessge", this.idMsg);
        const resp = this.$store.state.automation.cancelSendMessage;
        if (resp.result === 1) {
          this.successAlert().then(() => {
            this.getListSegmentation();
          });
        } else {
          this.isLoading = false;
          this.errorAlert(resp.message);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
