<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(name)="data">
            <router-link :to="'/sendmessage/detail/' + data.item.id">
              <u> {{ data.item.name }}</u>
            </router-link>

            <font-awesome-icon
              v-if="data.item.message_id == 0"
              icon="exclamation-circle"
              class="ml-2 f-16"
              v-b-tooltip="{
                title:
                  'Processing your request, please wait a few moment. If this icon still show after some time pass, please contact admin.',
                placement: 'left',
                variant: 'warning',
              }"
            />
            <!-- title="Processing your request, please wait a few moment. If this icon still show after some time pass, please contact admin." -->
          </template>
          <template v-slot:cell(send_time)="data">
            <div>{{ data.item.send_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.send_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(status_id)="data">
            <div class="d-flex justify-content-center">
              <span v-if="data.item.status_id === 1" class="text-danger"
                >Cancel</span
              >
              <span v-if="data.item.status_id === 2" class="text-success"
                >Sent</span
              >
              <span v-if="data.item.status_id === 3" class="status-3"
                >Send immediately</span
              >
              <span v-if="data.item.status_id === 4" class="status-4"
                >Schedule a post</span
              >
              <span v-if="data.item.status_id === 5" class="status-5"
                >Draft</span
              >
            </div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex justify-content-center">
              <b-button
                variant="link"
                class="text-warning px-1 py-0"
                @click.prevent="editMessage(data.item.id)"
              >
                <font-awesome-icon icon="pencil-alt" title="Edit" />
              </b-button>
            </div>
          </template>
          <template v-slot:cell(list)="data">
            <div class="d-flex">
              <b-dropdown
                v-if="
                  data.item.status_id === 2 ||
                  data.item.status_id === 3 ||
                  data.item.status_id === 4
                "
                size="md"
                variant="link"
                toggle-class="text-decoration-none text-gray p-0"
                no-caret
              >
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-v" class="mt-2 pointer" />
                </template>
                <b-dropdown-item-button
                  v-if="data.item.status_id === 2 || data.item.status_id === 3"
                  @click.prevent="resendMessage(true, data.item.id)"
                  >Repeat</b-dropdown-item-button
                >
                <b-dropdown-item-button
                  v-if="data.item.status_id === 4"
                  @click.prevent="resendMessage(false, data.item.id)"
                  :disabled="data.item.message_id == 0"
                  >Cancel</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <Pagination
      @handleChangeTake="hanndleChangePerpage"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  props: {
    fields: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  methods: {
    editMessage(id) {
      this.$emit("edit", id);
    },
    resendMessage(status, id) {
      if (status) {
        this.$emit("resend", id);
      } else {
        this.$emit("cancel", id);
      }
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.toShow = this.filter.take;
      this.$emit("perpage", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.toShow = this.showing + this.filter.take;
      }
      this.$emit("page", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.status-3 {
  color: #5d00b4;
}
.status-4 {
  color: #00b9ff;
}
.status-5 {
  color: #9524f3;
}
::v-deep .btn-group > .btn,
.btn-group-vertical > .btn {
  color: gray;
}

::v-deep .table-responsive {
  @media (max-width: 767.98px) {
    overflow-x: scroll;
  }
}
::v-deep .dropdown-menu {
  left: -100px !important;
}
::v-deep .dropdown-menu {
  @media (max-width: 767.98px) {
    left: 0px !important;
  }
}
.color-time {
  color: #8f8f8f;
  font-size: 14px;
}
.btn-name {
  background-color: transparent;
  border: none;
  color: #4f5d73;
}
.btn-name:hover {
  text-decoration: underline;
}
</style>
